import headshot2 from "../picture/headshot_new.JPG";
import intro from "../picture/intro.jpg";
import Swing from "react-reveal/Swing";

const Intro = () => {
  return (
    <section
      id="about"
      class=".bg-light.bg-gradient mt-md-4 mx-md-5 pt-md-4 px-md-4 pb-md-4"
    >
      <div className={"mt-5 narrow mx-5 "}>
        <img src={headshot2} class="mb-4 headshot" />
        <div class="fs-3">
          <span class="d-flex">
            Hi<Swing class="inline">👋</Swing>
          </span>
          <span class="fs-3 animate__animated animate__fadeInUp">
            My name is <span class="fw-bold emphasize fs-2">Jiahao Ye</span>
            <span class="fst-italic fs-4"> he/him</span>
          </span>
        </div>
        <div class="fst-italic mb-2 mt-4 animate__animated animate__fadeInUp">
          I am passionate about
        </div>
        <div class="container d-flex flex-wrap">
          <div class="tag animate__animated animate__fadeInUp animate__delay-1s">
            Coffee
          </div>
          <div class="tag animate__animated animate__fadeInUp animate__delay-1s">
            Olympic Weightlifting
          </div>
          <div class="tag animate__animated animate__fadeInUp animate__delay-1s">
            Photography
          </div>
          <div class="tag animate__animated animate__fadeInUp animate__delay-1s">
            Filming
          </div>
          <div class="tag animate__animated animate__fadeInUp animate__delay-1s">
            Outdoors
          </div>
        </div>
        <div class="mt-4 animate__animated animate__fadeInUp animate__delay-2s">
          <p class="fs-8 lh-lg">I'm based in San Francisco.</p>
          <p class="fs-8 lh-lg">
            I was born in Deqing, China. I went to boarding schools in Hangzhou
            for 6 years, where I graduated from Hangzhou Foreign Languages
            School in 2018.
          </p>
          <p class="fs-8 lh-lg">
            {" "}
            I'm a proud alum of{" "}
            <a
              className="link"
              href="https://www.forbes.com/sites/forbespr/2015/07/29/pomona-college-ranks-no-1-on-forbes-8th-annual-rankings-of-americas-top-colleges/?sh=4388dc6855fa"
            >
              Pomona College
            </a>{" "}
            with a B.A. degree in Computer Science major and Politics minor (Dec
            2022).{" "}
          </p>
          <p class="fs-8 lh-lg">
            Like a typical liberal arts college kid, I choose to not look away
            from structural inequalities and societal issues. My curiosity took
            me to Uganda, where I spent a month studying international
            development with{" "}
            <a href="https://www.washingtonpost.com/people/laura-seay/">
              Professor Seay
            </a>
            .
          </p>
          <p class="fs-8 lh-lg">
            If you're up for some good coffee or just want to meet my cat,{" "}
            <a href="#life">cat 小宝 (Xiaobao)</a>, feel free to reach out.
            Let's connect!
          </p>
          <img src={intro} class="mb-4 intro" />
        </div>
      </div>
    </section>
  );
};

export default Intro;
